<template>
  <div>
    <InfoModalFullScreen
      ref="infoModal"
      title="Well this is a bummer."
      text="No trips are available for your dates and budget. Time to switch it up."
      cta="Try Again"
      @hide="goBack"
      @cta="goBack"
    />
    <LoadingModalFullScreen
      ref="loadingModal"
      :text="message"
      @close="onAbort"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { SEARCH } from '../../constants/SEARCH'
import LoadingModalFullScreen from '../../components-rf/modals/LoadingModalFullScreen.vue'
import InfoModalFullScreen from '../../components-rf/modals/InfoModalFullScreen.vue'
const messages = [
  'THE WORLD IS YOUR OYSTER, JUST A FEW MORE MINUTES..',
  "PREPARING FOR TAKEOFF.. WAIT UNTIL YOU SEE WHAT'S COMING!",
  'PREPARING FOR A CHANGE OF ALTITUDE, ONE SECOND!',
  "HOLD TIGHT! WE'RE PREPARING FOR TAKEOFF.",
]

function* messageGen(steps) {
  while (true) yield* steps
}
const messageGenerator = messageGen(messages)

export default {
  name: 'SearchStream',
  created() {
    const searchParameters = this.parseQueryParameters(this.$route.query)
    this.searchType = searchParameters.searchType
    this.startSearch({
      form: searchParameters,
      onSuccess: this.onSuccess,
      onFail: this.onFail,
    })
  },
  data() {
    return {
      searchType: undefined,
      message: messageGenerator.next().value,
    }
  },
  mounted() {
    this.$refs.loadingModal.show()
    setInterval(() => {
      this.message = messageGenerator.next().value
    }, 3000)
  },
  computed: mapGetters('SearchForm', ['parseQueryParameters']),
  methods: {
    ...mapActions('SearchStream', ['startSearch', 'stopSearch']),
    onSuccess() {
      const route =
        this.searchType === SEARCH.TYPE.PACKAGE
          ? '/search/shortlist'
          : '/search/summary'
      this.$router.replace(route)
    },
    onAbort() {
      this.stopSearch()
      this.goBack()
    },
    goBack() {
      this.$router.replace('/search/form')
    },
    onFail() {
      this.$refs.loadingModal.hide()
      this.$refs.infoModal.show()
    },
  },
  components: { LoadingModalFullScreen, InfoModalFullScreen },
}
</script>

<style scoped lang="scss"></style>
